//
//
//
//
//
//

import { mapActions } from 'vuex';

export default {
  data() {
    return {
      ready: false,
    };
  },

  async mounted() {
    this.setGeneralLoader(true);
    const { id } = this.$route.params;
    await this.loadUser(id);
    this.setGeneralLoader(false);
    this.ready = true;
  },

  methods: {
    ...mapActions('common', ['setGeneralLoader']),
    ...mapActions('user', ['loadUser']),
  },
};
